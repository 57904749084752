'use client'
import { createContext, useContext, useState } from 'react'

const init: any = {
  videoReady: {},
  setVideoReady: () => {}
}

const VideoReadyContext = createContext(init)

export const ProviderVideoReady = ({ value = null, children }) => {
  const [videoReady, setVideoReady] = useState(value)

  return <VideoReadyContext.Provider value={{ videoReady, setVideoReady }}>
    {children}
  </VideoReadyContext.Provider>
}

export const useVideoReady = () => {
  const { videoReady, setVideoReady } = useContext(VideoReadyContext)
  return { videoReady, setVideoReady }
}
