import { TypeLang } from '@/schema'
export const LOCALE = process.env.NEXT_PUBLIC_LOCALE as TypeLang
export const HOSTNAME = process.env.NEXT_PUBLIC_PROJECT_HOSTNAME
export const CLIENT_NAME = process.env.NEXT_PUBLIC_CLIENT_NAME
export const DEV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
export const STAGING = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
export const PRO = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export * from './shuffle'
export * from './slugify'
export * from './icons'
export * from './sortBy'
export * from './cookie'
export * from './inputAutoComplete'
export * from './inputPatterns'
export * from './dates'

export const getRoundedPercentage = (value: number, maxValue: number): number => {
  if (maxValue === 0) {
    console.warn('maxValue is 0')
    return Math.round(100 + value)
  }

  const percentage = (value / maxValue) * 100
  return Math.round(percentage)
}
