import { css } from 'styled-components'
import { mq, vw } from './config'
// import { mq } from '.'

const rem = {
  baseMobile: 15,
  base: 18,
  get: (value:number, factor = null) => `${value / (factor ?? rem.baseMobile)}rem`
}

export const getP75 = () => {
  return css`
    font-size: ${vw(50, 'mobile')};
    font-weight: 500;
    letter-spacing: -.91px;
    line-height: ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(75, 'desktop')};
      letter-spacing: -1.36px;
      line-height: ${vw(75, 'desktop')};
    }
  `
}

export const getP98 = () => {
  return css`
    font-size: ${vw(50, 'mobile')};
    font-weight: 500;
    letter-spacing: -.91px;
    line-height: ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(98, 'desktop')};
      letter-spacing: -1.36px;
      line-height: ${vw(98, 'desktop')};
    }
  `
}

export const getP40Contents = () => {
  return css`
    font-size: ${vw(25, 'mobile')};
    font-weight: 400;
    letter-spacing: -.25px;
    line-height: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(40, 'desktop')};
      letter-spacing: -.2px;
      line-height: ${vw(48, 'desktop')};
    }
  `
}

export const getP40 = () => {
  return css`
    font-size: ${vw(30, 'mobile')};
    font-weight: 500;
    letter-spacing: -.55px;
    line-height: ${vw(37, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(40, 'desktop')};
      letter-spacing: -.2px;
      line-height: ${vw(48, 'desktop')};
    }
  `
}

export const getP40Mobile = () => {
  return css`
    font-size: ${vw(30, 'mobile')};
    font-weight: 500;
    letter-spacing: -.55px;
    line-height: ${vw(37, 'mobile')};
  `
}

export const getP35 = () => {
  return css`
    font-size: ${vw(30, 'mobile')};
    font-weight: 500;
    letter-spacing: -.2px;
    line-height: ${vw(40, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(35, 'desktop')};
      line-height: ${vw(45, 'desktop')};
    }
  `
}

export const getP35Contents = () => {
  return css`
    font-size: ${vw(25, 'mobile')};
    font-weight: 500;
    letter-spacing: -.2px;
    line-height: ${vw(28, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(35, 'desktop')};
      line-height: ${vw(45, 'desktop')};
    }
  `
}

export const getP30Contents = () => {
  return css`
    font-size: ${vw(20, 'mobile')};
    font-weight: 400;
    letter-spacing: -.36px;
    line-height: ${vw(24, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(30, 'desktop')};
      letter-spacing: -.2px;
      line-height: ${vw(37, 'desktop')};
    }
  `
}

export const getP30 = () => {
  return css`
    font-size: ${vw(25, 'mobile')};
    font-weight: 500;
    letter-spacing: -.45px;
    line-height: ${vw(34, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(30, 'desktop')};
      letter-spacing: -.2px;
      line-height: ${vw(40, 'desktop')};
    }
  `
}

export const getP20 = () => {
  return css`
    font-size: ${vw(20, 'mobile')};
    font-weight: 400;
    letter-spacing: -.3px;
    line-height: ${vw(28, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(20, 'desktop')};
      letter-spacing: -.2px;
      line-height: ${vw(30, 'desktop')};
    }
  `
}

export const getP20Mobile = () => {
  return css`
    font-size: ${vw(20, 'mobile')};
    font-weight: 400;
    letter-spacing: -.3px;
    line-height: ${vw(28, 'mobile')};
  `
}

export const getP14Mobile = () => {
  return css`
    font-size: ${vw(14.6, 'mobile')};
    font-weight: 400;
    letter-spacing: -.15px;
    line-height: ${vw(22, 'mobile')};
  `
}

export const getP12Mobile = () => {
  return css`
    font-size: ${vw(12, 'mobile')};
    font-weight: 400;
    letter-spacing: -.15px;
    line-height: ${vw(22, 'mobile')};
  `
}

export const getP15 = () => {
  return css`
    font-size: ${vw(15, 'mobile')};
    font-weight: 400;
    letter-spacing: -.19px;
    line-height: ${vw(22, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(15, 'desktop')};
      letter-spacing: 0;
      line-height: ${vw(22, 'desktop')};
    }
  `
}

export const getP15_2 = () => {
  return css`
    font-size: ${vw(13, 'mobile')};
    font-weight: 400;
    letter-spacing: -.19px;
    line-height: ${vw(22, 'mobile')};

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(15, 'desktop')};
      letter-spacing: 0;
      line-height: ${vw(22, 'desktop')};
    }
  `
}

export const getP93_2 = () => {
  return css`
    font-size: ${vw(50, 'mobile')};
    font-weight: 500;
    line-height: ${vw(50, 'mobile')};
    letter-spacing: -1.36px;

    ${mq.greaterThan('tablet')} {
      font-size: ${vw(105, 'desktop')};
      line-height: ${vw(105, 'desktop')};
      letter-spacing: -1.36px;
    }
  `
}

export const font8_5 = (mobile: boolean = true, weight: 400 = 400) => css`
  font-size: ${rem.get(8, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(5.5, rem[mobile ? 'baseMobile' : 'base'])};
  letter-spacing: -.16px;
`

export const getP18InputLabel = (mobile: boolean = true, weight: 400 = 400) => css`
  font-size: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: normal;
  letter-spacing: -.3px;
`

export const getP18InputHasContentLabel = (mobile: boolean = true, weight: 400 = 400) => css`
  font-size: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: normal;
  letter-spacing: -.3px;
`


//  MARGINS
export const getMargin150 = () => {
  return css`
    margin-bottom: ${vw(100, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(150, 'desktop')};
    }
  `
}

export const getMargin100 = () => {
  return css`
    margin-bottom: ${vw(60, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(100, 'desktop')};
    }
  `
}

export const getMargin50 = () => {
  return css`
    margin-bottom: ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(50, 'desktop')};
    }
  `
}

export const getMargin40 = () => {
  return css`
    margin-bottom: ${vw(40, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(40, 'desktop')};
    }
  `
}

export const getMargin20 = () => {
  return css`
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(20, 'desktop')};
    }
  `
}

export const getMargin15 = () => {
  return css`
    margin-bottom: ${vw(12, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(15, 'desktop')};
    }
  `
}

export const getMargin10 = () => {
  return css`
    margin-bottom: ${vw(10, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(10, 'desktop')};
    }
  `
}

export const getMargin5 = () => {
  return css`
    margin-bottom: ${vw(5, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(5, 'desktop')};
    }
  `
}

export const getMargin3 = () => {
  return css`
    margin-bottom: ${vw(3, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(3, 'desktop')};
    }
  `
}
