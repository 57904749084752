'use client'
import { createContext, useState } from 'react'
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react'

type TypeOverlay = {
  isActive: boolean,
  setIsActive: Dispatch<SetStateAction<boolean>>
}

export const OverlayContext = createContext<TypeOverlay | null>(null)

interface Props {
  children: ReactNode
}

export const OverlayProvider:FC<Props> = ({ children }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <OverlayContext.Provider value={{ isActive, setIsActive }}>
      {children}
    </OverlayContext.Provider>
  )
}
