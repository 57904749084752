import { useEffect, useState, useRef, useCallback } from 'react'
import type { RefObject } from 'react'

interface IntersectionOptions extends IntersectionObserverInit {
  threshold?: number | number[]
  rootMargin?: string
  triggerOnce?: boolean
  initialInView?: boolean
  skip?: boolean
}

type InViewHookResponse = {
  ref: RefObject<any> | ((node?: Element | null) => void)
  inView: boolean
  entry?: IntersectionObserverEntry
}

export const useInView = (options: IntersectionOptions = {}): InViewHookResponse => {
  const ref = useRef<Element | null>(null)
  const [inView, setInView] = useState<boolean>(options?.initialInView || false)
  const [entry, setEntry] = useState<IntersectionObserverEntry | undefined>(undefined)

  const observerCallback = useCallback((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    const [firstEntry] = entries

    setInView(firstEntry.isIntersecting)
    setEntry(firstEntry)

    if (options.triggerOnce && firstEntry.isIntersecting) {
      observer.disconnect()
    }
  }, [options.triggerOnce])

  useEffect(() => {
    // Ensure this runs only in the browser
    if (typeof window === 'undefined' || options.skip || !ref.current) return

    const observerOptions: IntersectionObserverInit = {
      threshold: options.threshold || 0,
      rootMargin: options.rootMargin || '0px',
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)
    const currentRef = ref.current

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      observer.disconnect()
    }
  }, [observerCallback, options.threshold, options.rootMargin, options.skip])

  return { ref, inView, entry }
}
