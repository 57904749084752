'use client'
import { createContext, useEffect, useState } from 'react'
import type { FC, ReactNode } from 'react'

export enum MODALS {
  // COOKIES = 'modal_cookies',
  // NEWSLETTER= 'modal_newsletter',
  // VIDEO='modal_video'
  MENU = 'modal_menu',
  VIDEO= 'modal_video',
  CONTACT='modal_contact',
}

export interface iDefaultModal { isActive: boolean, payload?: any }

// interface iModalAuthPayload {
//   payload: {
//     level: string
//   }
// }

type TypeModalException = {
  // [MODALS.AUTH]: iModalAuthPayload
}

// Utility to merge two Definitions: B object properties shadowing -> A object properties
type $Merge<TA, TB> = Omit<TA, keyof TB> & TB;
// Getting the Base+Extended type of an Modal
type TypeModalById<A extends MODALS> = A extends keyof TypeModalException ? $Merge<iDefaultModal, TypeModalException[A]> : iDefaultModal


type TypeModals = {
  [key in MODALS]?: TypeModalById<key>
}
type TypeModalsState = {
  modals: TypeModals,
  setModal: (modal: { [key: string]: iDefaultModal } | ((prev: TypeModals) => TypeModals)) => void,
  someModalIsActive: boolean
}

const initialModalsState: TypeModalsState = {
  modals: {},
  setModal: () => {},
  someModalIsActive: false
  //someModalIsActive: boolean at least one modal is Active ?
}

interface iModalsProviderProps {
  children: ReactNode
}

export const ModalsContext = createContext<TypeModalsState | null>(initialModalsState)

export const ModalsProvider:FC<iModalsProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<TypeModals>({})
  const [someModalIsActive, setSomeModalIsActive] = useState(false)

  const setModal = (modal) => {
    setModals(prevModals => ({...prevModals, ...modal }))
    let someActive = false

    const modalEnumName = Object.keys(modal)[0]
    const modalObj = modal[modalEnumName]
    if (modalObj.isActive) someActive = true
    else {
      for (const [enumName, m] of Object.entries(modals)) {
        if (enumName !== modalEnumName && m.isActive) {
          someActive = true
          break
        }
      }
    }

    setSomeModalIsActive(someActive)
  }

  useEffect(() => {
    const html = document.body.parentElement
    if(someModalIsActive) {
      if(html.style.overflow === 'hidden') return
      else {
        html.style.overflow = 'hidden'
      }
    } else {
      html.style.overflow = 'initial'
    }
  }, [someModalIsActive])

  return (<ModalsContext.Provider value={{ modals, setModal, someModalIsActive }}>
    {children}
  </ModalsContext.Provider>)
}
