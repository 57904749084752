// import 'server-only'
import type { TypeLang } from '@/schema'

type Dictionaries = {
  [K in TypeLang]?: () => Promise<any>;
};

export const dictionaries:Dictionaries = {
  en: () => import('./dictionaries/en.json').then(module => module.default),
  es: () => import('./dictionaries/es.json').then(module => module.default),
}

export const getDictionary = async (locale) => dictionaries[locale]()
